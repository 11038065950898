.card {
    width: 250px;
    height: 230px;
    border: 2px solid #e5e5e5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
}

.card h4 {
    margin: 1.5rem 0 1rem 0;
    color: #333333;
    font-size: 1rem;
    font-weight: 600;
}

.card h1 {
    margin: 1rem 0 0 0;
    color: #d5d5d5;
    font-size: 3rem;
    font-weight: 400;
}

/* @media only screen and (max-width: 991px) {} */

@media only screen and (max-width: 730px) {
    .card {
        width: 100%;
    }
}