.pageCard {
    width: 94%;
    max-width: 1320px;
    background-color: var(--color-white);
    margin: 3% auto;
    box-shadow: 0 0 40px -10px rgba(0,0,0,.2);
    border-radius: var(--card-border-radius);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 991px) {
    .pageCard {
        width: 100%;
        margin: 0;
        border-radius: 0;
        min-height: 100vh;
    }
}