.container {
  display: flex;
  flex-direction: row;
  color: var(--color-gray-600);
}

.detailsContainer {
  padding: 0 1rem;
}

.title {
  color: var(--color-gray-700);
}

.container p,
.container h4,
.container h5,
.container h3 {
  margin: 0;
}


@media only screen and (max-width: 630px) {
  .icon {
    display: none;
  }
}

/*
14px for the title details
12px for the text


color: #666;
  opacity: .7;


Title: 16px | 600
Sub title: 14px | 400
Text: 12px | opacity: .7 | 400

*/
