.titleContainer {
  width: 100%;
}

.bottomMargin {
  margin-bottom: 1.875rem;
}

.topMargin {
  margin-top: 1.875rem;
}

.titleBorder {
  position: relative;
  display: inline-block;
  font-size: 1.3125rem;
  padding-bottom: 0.4375rem;
  color: var(--color-gray-700);
}

.titleBorder::before,
.titleBorder::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 30%;
  background-color: var(--color-vibrant-blue);
}

.titleBorder::after {
  left: 30%;
  width: 70%;
  background-color: var(--color-gray-300);
}
