.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 20000;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: var(--color-gray-700);
  }

  .navLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 1.5rem;
    list-style: none;
  }

  .navLinks a {
    text-decoration: none;
    opacity: 1;
    color: var(--color-gray-800);
  }

  .closeIcon {
    position: fixed;
    top: 20px;
    right: 20px;
  }
  