.footer {
    background-color: #fcfcfc;
    border-color: #eeeeee;
    border-top: 2px solid #f2f2f2;
    border-bottom-right-radius: var(--card-border-radius);
    border-bottom-left-radius: var(--card-border-radius);
    padding-left: calc(var(--card-border-radius) + 10px);
    padding-right: calc(var(--card-border-radius) + 10px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8125rem;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 3.5rem;
}

.footer a {
    text-decoration: none;
    color: #333;
    opacity: .6
}

.footer a:hover {
    opacity: 1;
    color: #222;
}

.links ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0;
    margin: 0;
}

.footerText {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 991px) {
    .footer {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media (max-width: 600px) {
    .footer {
      flex-direction: column;
      align-items: center;
    }
    .links {
        margin: 6px 0;
        padding: 0;
    }
    .footerText {
        margin: 6px 0;
        font-weight: 600;
        padding: 0;
    }
}