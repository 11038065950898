.container {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    
}

.links {
    display: flex;
    gap: 4rem;
}

.flexList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5rem;
    color: var(--color-gray-600);
}

.text p {
    margin: 0;
}

@media only screen and (max-width: 1320px) {
    .links {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        flex-direction: column-reverse;   
    }

    .text {
        padding: 0;
    }

    .links {
        margin-top: 3rem;
        align-items: flex-start;
    }
}


/* 

991px break
768px
*/