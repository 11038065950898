.certification {
  color: var(--color-gray-600);
  display: flex;
  flex-direction: row;
}

.certification p,
.certification h3 {
  margin: 0;
}

.title {
  color: var(--color-gray-700);
}

.certDetails {
  padding: 0 1rem;
}
.statusContainer {
    margin-top: .125rem;
}

.status {
  font-size: 0.875rem;
  padding: 0rem 1rem;
  display: inline-block;
  border-radius: 1rem;
}

.active {
  background-color: #ccffcc;
  color: #009900;
}



.expired {
  background-color: #ffcccc;
  color: #ff6666;
}

.link {
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 630px) {
  .icon {
    display: none;
  }
}

/* --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px; */
