.skill {
  margin: 0;
  box-shadow: 0 10px 10px -8px var(--color-gray-400);
  color: var(--color-gray-600);
  border: 2px solid var(--color-gray-400);
  border-radius: 2rem;
  padding: .3rem .875rem;
  font-size: .875rem;
}

.skill:hover {
    background-color: var(--color-gray-400);
    cursor: pointer;
}

.skill:active {
  border-color: var(--color-gray-600);
}