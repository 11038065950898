.header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding-left: calc(var(--card-border-radius) + 10px);
    padding-right: calc(var(--card-border-radius) + 10px);
    padding-top: var(--card-border-radius);
    padding-bottom: var(--card-border-radius);
    box-sizing: border-box;
}

.nav {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 991px) {
    .header {
        padding: 0px 20px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.08);
    }
}